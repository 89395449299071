













import { mapGetters } from "vuex";
import IssueModal from "@/views/calendar/IssueModal.vue";

export default {
  components: { IssueModal },
  props: ["id"],
  data() {
    return {
      selectedIssueId: null,
    };
  },
  computed: {
    ...mapGetters({}),
  },
  methods: {
    closeIssueModal() {
      this.selectedIssueId = null;
    },
  },
  mounted() {
    this.selectedIssueId = parseInt(this.id);
    // this.$store.dispatch('Enumeration/list/getStatuses');
    this.$store.dispatch('Project/list/getList');
  },
};
